import { Controller } from "@hotwired/stimulus";
import axios from "axios";

export default class DecisionMatrixController extends Controller {
  static targets = ["matrixBody", "columnHeader"];

  connect() {
    console.debug('DecisionMatrixController connected');
    this.addInitialRows(); // Add initial row
  }


  addInitialRows() {
    /**
     * format:
     * [{"id":0,"title":"Django","decisions":[{"state":"yes","column":0}]}]
     */
    const rows = JSON.parse(this.element.dataset.rows);
    rows.forEach(row => this.addRow(row));
  }

  /**
   * @param {{id: number, title: string, decisions: {state: string, column: number}[]}} rowData
   */
  addRow(rowData) {
    const rowIndex = this.matrixBodyTarget.children.length;
    const row = document.createElement('tr');

    // Add row header cell
    const headerCell = document.createElement('td');
    headerCell.className = 'row-header-cell';

    // Create a container for input and delete button
    const headerContainer = document.createElement('div');
    headerContainer.className = 'd-flex align-items-center';

    // Add input
    const headerInput = document.createElement('input');
    headerInput.type = 'text';
    headerInput.className = 'row-header-input form-control';
    headerInput.placeholder = `Row ${rowIndex + 1}`;
    if (rowData.title) {
      headerInput.value = rowData.title;
    }
    headerInput.dataset.action = 'blur->decision-matrix#updateRowHeader';
    headerInput.dataset.rowIndex = rowIndex;

    // Add delete button
    const deleteButton = document.createElement('button');
    deleteButton.type = 'button';
    deleteButton.className = 'delete-row-btn';
    deleteButton.innerHTML = '×';
    deleteButton.dataset.action = 'click->decision-matrix#deleteRow';

    headerCell.appendChild(headerInput);
    headerCell.appendChild(deleteButton);
    row.appendChild(headerCell);

    // Add decision cells for each column
    if (rowData && rowData.decisions) {
      rowData.decisions.forEach(decision => {
        const cell = document.createElement('td');
        cell.className = 'matrix-cell';
        const circle = document.createElement('div');
        circle.className = 'decision-circle';
        switch (decision.state) {
          case 'yes':
            circle.classList.add('state-yes');
            break;
          case 'no':
            circle.classList.add('state-no');
            break;
        }
        circle.dataset.state = decision.state;
        circle.dataset.row = rowIndex;
        circle.dataset.column = decision.column;
        circle.dataset.action = 'click->decision-matrix#toggleState';
        cell.appendChild(circle);
        row.appendChild(cell);
      });
    } else {
      const columnCount = this.columnHeaderTargets.length;
      for (let i = 0; i < columnCount; i++) {
        const cell = document.createElement('td');
        cell.className = 'matrix-cell';
  
        const circle = document.createElement('div');
        circle.className = 'decision-circle';
        circle.dataset.state = 'empty';
        circle.dataset.row = rowIndex;
        circle.dataset.column = i;
        circle.dataset.action = 'click->decision-matrix#toggleState';
  
        cell.appendChild(circle);
        row.appendChild(cell);
      }
    }

    this.matrixBodyTarget.appendChild(row);
  }

  toggleState(event) {
    const circle = event.target;
    const currentState = circle.dataset.state;

    // Cycle through states: empty -> yes -> no -> empty
    let newState;
    switch (currentState) {
      case 'empty':
        newState = 'yes';
        circle.classList.add('state-yes');
        circle.classList.remove('state-no');
        break;
      case 'yes':
        newState = 'no';
        circle.classList.add('state-no');
        circle.classList.remove('state-yes');
        break;
      case 'no':
        newState = 'empty';
        circle.classList.remove('state-no');
        break;
    }

    circle.dataset.state = newState;

    console.log(`Toggled cell at row ${circle.dataset.row}, column ${circle.dataset.column} to ${newState}`);
    // Here you would typically send this data to your backend
  }

  updateColumnHeader(event) {
    const input = event.target;
    const column = input.dataset.column;
    console.log(`Updated column ${column} header to: ${input.value}`);
    // Here you would typically send this data to your backend
  }

  updateRowHeader(event) {
    const input = event.target;
    const rowIndex = input.dataset.rowIndex;
    console.log(`Updated row ${rowIndex} header to: ${input.value}`);
    // Here you would typically send this data to your backend
  }

  deleteRow(event) {
    event.preventDefault();
    const button = event.target;
    const row = button.closest('tr');

    if (confirm('Are you sure you want to delete this row?')) {
      row.remove();
      this.reindexRows();
    }
  }

  reindexRows() {
    // Update row indices and placeholders after deletion
    const rows = this.matrixBodyTarget.querySelectorAll('tr');
    rows.forEach((row, index) => {
      const input = row.querySelector('.row-header-input');
      input.dataset.rowIndex = index;
      input.placeholder = `Row ${index + 1}`;

      // Update data-row attributes for circles in this row
      const circles = row.querySelectorAll('.decision-circle');
      circles.forEach(circle => {
        circle.dataset.row = index;
      });
    })
  }

  addColumn() {
    // Add header
    const headerRow = this.element.querySelector('thead tr');
    const columnIndex = this.columnHeaderTargets.length;

    const headerCell = document.createElement('th');
    headerCell.className = 'column-header-cell';

    const headerContainer = document.createElement('div');
    headerContainer.className = 'd-flex align-items-center position-relative';

    const headerInput = document.createElement('input');
    headerInput.type = 'text';
    headerInput.className = 'form-control header-input';
    headerInput.placeholder = `Column ${columnIndex + 1}`;
    headerInput.dataset.decisionMatrixTarget = 'columnHeader';
    headerInput.dataset.column = columnIndex;
    headerInput.dataset.action = 'blur->decision-matrix#updateColumnHeader';

    const deleteButton = document.createElement('button');
    deleteButton.type = 'button';
    deleteButton.className = 'delete-column-btn';
    deleteButton.innerHTML = '×';
    deleteButton.dataset.action = 'click->decision-matrix#deleteColumn';
    deleteButton.dataset.column = columnIndex;

    headerContainer.appendChild(headerInput);
    headerContainer.appendChild(deleteButton);
    headerCell.appendChild(headerContainer);

    // Insert before the add column bar
    headerRow.appendChild(headerCell);

    // Add cells to existing rows
    const rows = this.matrixBodyTarget.querySelectorAll('tr');
    rows.forEach((row, rowIndex) => {
      const cell = document.createElement('td');
      cell.className = 'matrix-cell';

      const circle = document.createElement('div');
      circle.className = 'decision-circle';
      circle.dataset.state = 'empty';
      circle.dataset.row = rowIndex;
      circle.dataset.column = columnIndex;
      circle.dataset.action = 'click->decision-matrix#toggleState';

      cell.appendChild(circle);
      row.appendChild(cell);
    });
  }

  deleteColumn(event) {
    event.preventDefault();
    const button = event.target;
    const columnIndex = parseInt(button.dataset.column);

    if (confirm('Are you sure you want to delete this column?')) {
      // Remove header
      const headerCell = button.closest('th');
      headerCell.remove();

      // Remove corresponding cells in each row
      const rows = this.matrixBodyTarget.querySelectorAll('tr')
      rows.forEach(row => {
        const cells = row.querySelectorAll('td');
        cells[columnIndex + 1].remove(); // +1 because of row header cell
      });

      this.reindexColumns();
    }
  }

  reindexColumns() {
    // Update column indices and placeholders
    this.columnHeaderTargets.forEach((input, index) => {
      input.dataset.column = index;
      input.placeholder = `Column ${index + 1}`;

      // Update delete button
      const deleteButton = input.nextElementSibling;
      deleteButton.dataset.column = index;

      // Update circles in this column
      const circles = this.matrixBodyTarget.querySelectorAll(`.decision-circle[data-column="${index}"]`);
      circles.forEach(circle => {
        circle.dataset.column = index;
      });
    });
  }

  async saveMatrix() {
    const matrixData = {
      columns: this.columnHeaderTargets
        .filter(input => input.value)
        .map((input, index) => ({
          id: index,
          title: input.value
        })),
      rows: Array.from(this.matrixBodyTarget.querySelectorAll('tr'))
        .filter(row => row.querySelector('.row-header-input').value)
        .map((row, rowIndex) => {
          const headerInput = row.querySelector('.row-header-input')
          const circles = row.querySelectorAll('.decision-circle')
          
        return {
          id: rowIndex,
          title: headerInput.value || `Row ${rowIndex + 1}`,
          decisions: Array.from(circles).map(circle => ({
            column: parseInt(circle.dataset.column),
            state: circle.dataset.state
          }))
        }
      })
    }

    try {
      const response = await axios.patch(this.element.dataset.updateUrl, {
        data: matrixData
      }, {
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content
        }
      });

      if (response.data.status === 'ok') {
        // Show success message
        this.showNotification('Matrix saved successfully!', 'success')
      } else {
        throw new Error('Save failed')
      }
    } catch (error) {
      console.error('Error saving matrix:', error)
      this.showNotification('Failed to save matrix', 'error')
    }
  }

  showNotification(message, type = 'success') {
    const notification = document.createElement('div')
    notification.className = `alert alert-${type} position-fixed top-0 end-0 m-3`
    notification.style.zIndex = 1000
    notification.textContent = message

    document.body.appendChild(notification)
    setTimeout(() => notification.remove(), 3000)
  }
}
